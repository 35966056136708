import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { Container } from "reactstrap";
// import AddBox from "mdi-react/AddBoxIcon";
import Edit from "mdi-react/EditIcon";

import HomePlusIcon from "mdi-react/HomePlusIcon";

import firebase from "../../../database/firebase";
import "firebase/firestore";
// import { Button } from "reactstrap";
import { localeTh, tableIcons } from "../AllowEvent/configTable";
// import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import { toast } from "react-toastify";
// import { API_URL } from "../../../constants";
import { AuthContext } from "../../App/firebaseAuth";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
// import axios from "axios";

import ProjectorScreenIcon from "mdi-react/TelevisionIcon";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import CameraIcon from "mdi-react/CameraIcon";
import Laptop from "mdi-react/LaptopIcon";
import WifiIcon from "mdi-react/WifiIcon";
import VolumeHighIcon from "mdi-react/VolumeHighIcon";
import CalendarCheckIcon from "mdi-react/CalendarCheckIcon";
import CalendarRemoveIcon from "mdi-react/CalendarRemoveIcon";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import showResults from "../AddNewRoom/Show";
import showResults2 from "../AddNewRoom/Show2";
import AnimatedLineForm from "../AddNewRoom/components/AnimatedLineForm";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    // borderRadius: "50px"
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    borderRadius: "50px 50px 0 0",
  },
});

const firestore = firebase.firestore();

const AllPayment = (props) => {
  const [tableData, setTableData] = useState();
  const tableName = "จัดการห้องประชุม";
  const currentUser = useContext(AuthContext);
  const history = useHistory();

  const classes = useStyles();
  const [init,setInit] = useState(null)
  const [state, setState] = React.useState({
    bottom: false,
  });

  

  const toggleDrawer = (anchor, open , initVal) => (event) => {
    setInit(initVal)
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onCancle = (anchor) => {
    const userRef = firestore.collection("meeting-room");
      userRef
        .get()
        .then(function (querySnapshot) {
          let pmData = [];
          querySnapshot.forEach(function (pmDoc) {
            // doc.data() is never undefined for query doc snapshots
            if (pmDoc.exists) {
              const currentProduct = {
                id: pmDoc.id,
                ...pmDoc.data(),
              };
              pmData = [...pmData, currentProduct];
            }
          });
          setTableData(pmData);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    setState({ ...state, [anchor]: false });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container>
        <AnimatedLineForm
          onSubmit={init?showResults2:showResults}
          onCancle={onCancle}
          // event={event}
          isUpdate={init?true:false}
          initialValues={{ isShow:true , ...init }}
        />
      </Container>
    </div>
  );
  // const [isOpen, setIsOpen] = useState();
  // const [titleImg, setTitleImg] = useState();

  useEffect(() => {
    if (currentUser.currentUser.role <= 1) {
      history.push("/dashboard");
    }
    firebase.auth().onAuthStateChanged((user) => {
      const userRef = firestore.collection("meeting-room");
      userRef
        .get()
        .then(function (querySnapshot) {
          let pmData = [];
          querySnapshot.forEach(function (pmDoc) {
            // doc.data() is never undefined for query doc snapshots
            if (pmDoc.exists) {
              const currentProduct = {
                id: pmDoc.id,
                ...pmDoc.data(),
              };
              pmData = [...pmData, currentProduct];
            }
          });
          setTableData(pmData);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });
  }, [currentUser.currentUser.role, history]);

  return (
    <div>
      <Container className="text-center">
        {/* <h3 className="mb-2 text-center">{tableName}</h3> */}
        <Drawer
            anchor={"bottom"}
            open={state["bottom"]}
            // onClose={toggleDrawer("bottom", false)}
            PaperProps={{ className: classes.drawer }}
          >
            {list("bottom")}
          </Drawer>
        <MaterialTable
          columns={[
            { title: "ชื่อ", field: "name" },
            { title: "สถานที่", field: "location" },
            { title: "ความจุ", field: "capacity" },
            { title: "สิ่งอำนวยความสะดวก", render: (rowData) => {
              return (
                <span >
                { rowData.facility.cam && <CameraIcon  fontSize="small" />}
                { rowData.facility.control && <Laptop  fontSize="small" />}
                { rowData.facility.mic && <MicrophoneIcon  fontSize="small" />}
                { rowData.facility.monitor && <ProjectorScreenIcon  fontSize="small" />}
                { rowData.facility.wifi && <WifiIcon  fontSize="small" />}
                { rowData.facility.speaker && <VolumeHighIcon  fontSize="small" />}
              </span>
              )
            },
            cellStyle: {
              // backgroundColor: '#039be5',
              color: '#b5b5b5'
            },
           },
          ]}
          data={tableData}
          title={tableName}
          icons={tableIcons}
          options={{
            // exportButton: true,
            // showTitle: false,
            headerStyle: {
              backgroundColor: "#D7252A",
              color: "#ffffff",
              // padding: "3px",
            },
            rowStyle: (rowData) =>
              rowData.isShow
                ? { backgroundColor: "#e3ffe0", textAlign: "center" }
                : { backgroundColor: "#ffe5e5", textAlign: "center" },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            sorting: false,
            // grouping: true,
            actionsColumnIndex: -1,
            // tableLayout: "fixed",
          }}
          actions={[
            (rowData) => ({
              icon: () => <Edit />,
              tooltip: "แก้ไขข้อมูล",
              onClick: (event, rowData) => {
                toggleDrawer("bottom", true , rowData)(event)
              },
            }),
            (rowData) => ({
              icon: () => rowData.isShow?<CalendarRemoveIcon />:<CalendarCheckIcon />,
              tooltip: rowData.isShow?"ไม่ให้จอง":"ทำให้จองได้",
              onClick: (event, rowData) => {
                new Promise((resolve, reject) => {
                  if (currentUser.currentUser.role <= 1) {
                    toast.warning(
                      "ไม่สามารถดำเนินการได้"
                    );
                    reject();
                  } else {
                    firestore.collection("meeting-room").doc(rowData.id).update({
                      isShow: !rowData.isShow
                    })
                    .then(()=>{
                      const dataDelete = [...tableData];
                      const index = rowData.tableData.id;
                      dataDelete[index].isShow = !dataDelete[index].isShow ;
                      toast.success("แก้ไขเรียบร้อย");
                      setTableData([...dataDelete]);
                      resolve();
                    }) 
                    .catch((err)=>{
                      toast.warn("เกิดข้อผิดพลาด")
                      console.log(err)
                      reject()
                    })
                  }
                });
              },
            }),
            {
              icon: ()=> <HomePlusIcon />,
              tooltip: 'เพิ่มห้องประชุมใหม่',
              isFreeAction: true,
              onClick: toggleDrawer("bottom", true , null)
            }
          ]}
          localization={localeTh}
        />
        
      </Container>
    </div>
  );
};

export default AllPayment;
