import Moment from "moment";
import { extendMoment } from "moment-range";
import { SubmissionError } from "redux-form";
import OnOpenModal from './ModalInfo'

const moment = extendMoment(Moment);



/* eslint-disable no-alert */

export default (async function showResults(values) {
  const { timeStart, duration, event, data ,  ...newVal } = values;
  // console.log(values)
  const eventData = {
    ...newVal,
    duration: Number(duration),
    start: moment(timeStart).unix(),
    end: moment(timeStart).add(Number(duration), "minutes").unix(),
    roomName: data.name,
  };

  const rang1 = moment.range(moment.unix(eventData.start), moment.unix(eventData.end));
  values.event.forEach((evData) => {
    const rang2 = moment.range(moment(evData.start), moment(evData.end));
    if (rang1.overlaps(rang2)) {
      throw new SubmissionError({
        timeStart: "ช่วงเวลาที่คุณเลือก ถูกจองแล้ว",
      });
    }
  });
  const result2 = await OnOpenModal(eventData)
  console.log(result2)
  return result2

  // console.log("")

    

    
});
