import React from 'react';
import PropTypes from 'prop-types';
import {  useHistory } from 'react-router-dom';

const TopbarMenuLinks = ({ title, icon, path , collap }) => {
  const history = useHistory()
  return (
    <div className="topbar__link" onClick={()=>{
      collap()
      history.push(path)
    }} >
      <span className={`topbar__link-icon lnr lnr-${icon}`} />
      <p className="topbar__link-title">{title}</p>
    </div>
  );
}

TopbarMenuLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default TopbarMenuLinks;
