import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";
import Router from "./Router";
import store from "./store";
import ScrollToTop from "./ScrollToTop";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// import firebaseInit from "../../database/firebase";
import "firebase/auth";
import { AuthProvider } from "./firebaseAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Sarabun',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: "#D7252A",
      main: "#D7252A",
      dark: "#D7252A",
      contrastText: "#fff",
    },
    secondary: {
      light: "#000",
      main: "#000",
      dark: "#000",
      contrastText: "#fff",
    },
  },
});

const App = () => {

  

  return (
    <Provider store={store}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop>
              <Fragment>
                <div>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <Router />
                </div>
              </Fragment>
            </ScrollToTop>
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
