import axios from "axios";
import firebase from "firebase/app";
import Swal from "sweetalert2";
import { extendMoment } from "moment-range";
import Moment from "moment";
import config from "../../../constants";
import _ from 'lodash'

const moment = extendMoment(Moment);

const OnApprove = async (rowData, handleToggle, handleClose, tableData) => {
  // console.log("rowData", rowData);
  // console.log("tableData", tableData);
  handleToggle()
  const newTableData = _.filter(tableData, function(o) { return o.roomId === rowData.roomId; });
  // console.log(newTableData)
  
  let overlap = false;
  const rang1 = moment.range(
    moment.unix(rowData.start),
    moment.unix(rowData.end)
  );
  newTableData.forEach((evData) => {
    const rang2 = moment.range(
      moment.unix(evData.start),
      moment.unix(evData.end)
    );
    if (rang1.overlaps(rang2)) {
      if (!(rowData.id === evData.id) && evData.approved) {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "ช่วงเวลานี้ ของห้องนี้ถูกจองแล้ว จึงไม่สามารถจองซ้อนได้",
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#D7252A",
        });
        overlap = true;
        return false;
      }
    }
  });
  if (!overlap) {
    const idToken = await firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true);

    const result = await axios.post(
      config.API_URL + "/user/allow-meeting-event",
      {
        idToken,
        data: rowData,
      }
    );
    handleClose();
    if (result.data.status) {
      Swal.fire({
        title: "สำเร็จ",
        text: "อนุมัติคำขอเรียบร้อย",
        icon: "success",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#D7252A",
      });
      return true;
    } else {
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "มีข้อผิดพลาดบางอย่างเกิดขึ้น กรุณาลองใหม่อีกครั้ง",
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#D7252A",
      });
      return false;
    }
  }
};

export default OnApprove;
