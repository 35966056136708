import firebase from 'firebase/app';
// import "firebase/firestore";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCksWx-oizjznh8RnL5XWoenoyczluWZU0",
  authDomain: "toyota-khon-kaen.firebaseapp.com",
  projectId: "toyota-khon-kaen",
  storageBucket: "toyota-khon-kaen.appspot.com",
  messagingSenderId: "811539460255",
  appId: "1:811539460255:web:3d0f2b9b7e510e51d1f2aa",
  measurementId: "G-9LD789W0X0"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp;