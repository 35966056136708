import { Container, Typography } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Calendar from "./Calendar";
import firebase from "firebase/app";
import { useState } from "react";
import moment from "moment";

const firestore = firebase.firestore();
// import TimeLine from "./TimeLine";

export default function DetailRoom() {
  const { id } = useParams();
  const [event, setEvent] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    const getData = async () => {
      const roomData = await firestore.collection("meeting-room").doc(id).get();
      const eventData = await firestore
        .collection("booking-room")
        .where("roomId", "==", `${id}`)
        .where("approved", "==", true)
        .where("end", ">=", moment().unix())
        .get();
      setData(roomData.data());
      let events = [];
      eventData.forEach((thisEvent) => {
        if (thisEvent.exists) {
          const currentProduct = {
            allDay: false,
            ...thisEvent.data(),
            start: moment.unix(thisEvent.data().start).toDate(),
            end: moment.unix(thisEvent.data().end).toDate(),
          };
          // console.log(currentProduct)
          events = [...events, currentProduct];
        }
      });
      setEvent(events);
    };

    getData();
  }, [id]);
  return (
    <div>
      <Container style={{ position: "relative" }}>
        {/* {console.log(event ,moment().unix())} */}
        {data && (
          <img
            src={
              data.img
                ? data.img
                : "https://d2e5ushqwiltxm.cloudfront.net/wp-content/uploads/sites/107/2019/04/23031158/Salon1_UShape-Hero-Shot.jpg"
            }
            style={{
              width: "100%",
              height: "30vh",
              objectFit: "cover",
              objectPosition: "center center",
              filter: "brightness(30%)",
            }}
            alt="Main header"
          />
        )}
        <Typography
          variant="h5"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
          }}
        >
          {data && data.name}
        </Typography>
      </Container>
      <br />
      {/* <TimeLine /> */}
      {/* {console.log(event)} */}
      <Calendar event={event} roomId={id} data={data} />
    </div>
  );
}
