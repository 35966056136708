import React, { forwardRef } from "react";

import AddBox from "mdi-react/AddBoxIcon";
import ArrowDownward from "mdi-react/ArrowDownwardIcon";
import Check from "mdi-react/CheckIcon";
import ChevronLeft from "mdi-react/ChevronLeftIcon";
import ChevronRight from "mdi-react/ChevronRightIcon";
import Clear from "mdi-react/BackspaceIcon";
import DeleteOutline from "mdi-react/DeleteOutlineIcon";
import Edit from "mdi-react/EditIcon";
import FilterList from "mdi-react/FilterListIcon";
import FirstPage from "mdi-react/FirstPageIcon";
import LastPage from "mdi-react/LastPageIcon";
import Remove from "mdi-react/RemoveIcon";
import SaveAlt from "mdi-react/ContentSaveAlertIcon";
import Search from "mdi-react/SearchIcon";
import ViewColumn from "mdi-react/ViewColumnIcon";


export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const localeTh = {
  body: {
    emptyDataSourceMessage: "ไม่มีบันทึกที่จะแสดง",
    addTooltip: "เพิ่ม",
    deleteTooltip: "ลบ",
    editTooltip: "แก้ไข",
    filterRow: {
      filterTooltip: "กรอง",
    },
    editRow: {
      deleteText: "คุณต้องการลบบรรทัดนี้ไหม? เมื่อลบแล้วจะไม่สามารถทำการกู้คืนได้",
      cancelTooltip: "ยกเลิก",
      saveTooltip: "บันทึก",
    },
  },
  grouping: {
    placeholder: "ลากหัวตารางมาที่นี่ เพื่อจัดกลุ่ม",
    groupedBy: "จัดกลุ่มตาม:",
  },
  header: {
    actions: "การดำเนินการ",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} จาก {count}",
    labelRowsSelect: "แถว",
    labelRowsPerPage: "แถว ต่อ หน้า:",
    firstAriaLabel: "หน้าแรก",
    firstTooltip: "หน้าแรก",
    previousAriaLabel: "หน้าที่แล้ว",
    previousTooltip: "หน้าที่แล้ว",
    nextAriaLabel: "หน้าต่อไป",
    nextTooltip: "หน้าต่อไป",
    lastAriaLabel: "หน้าสุดท้าย",
    lastTooltip: "หน้าสุดท้าย",
  },
  toolbar: {
    addRemoveColumns: "เพิ่มหรือลบคอลัมน์",
    nRowsSelected: "ถูกเลือกแล้ว {0} แถว",
    showColumnsTitle: "ดูคอลัมน์",
    showColumnsAriaLabel: "ดูคอลัมน์",
    exportTitle: "ผู้ส่งออก",
    exportAriaLabel: "Expผู้ส่งออกorter",
    exportName: "Exporter en CSV",
    searchTooltip: "ค้นหา",
    searchPlaceholder: "ค้นหา",
  },
};
