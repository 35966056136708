import { Container } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';
import BigCalendar from './components/BigCalendar';
import EventLabels from './components/EventLabels';

const Calendar = ({event , roomId , data}) => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">ตารางเวลาการจองห้อง</h3>
        <h3 className="page-subhead subhead">
          ใช้สำหรับดูเวลาที่ห้องประชุมถูกใช้งาน
        </h3>
      </Col>
    </Row>
    <Row>
      <BigCalendar event={event} dir="false"  />
      <EventLabels roomId={roomId} event={event} data={data}  />
    </Row>
  </Container>
);

Calendar.propTypes = {
  rtl: RTLProps.isRequired,
};

export default connect(state => ({
  rtl: state.rtl,
}))(Calendar);
