import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import Calendar from '../../../../../shared/components/Calendar';
// import events from './events';

const BigCalendar = ({ dir , event }) => (
 
  <Col md={12} lg={12} xl={9}>
    {/* { console.log("dddd" , event)} */}
    {/* { console.log("cccc" , events)} */}
    <Card>
      <CardBody>
        {event && <Calendar dir={dir} events={event} />}
      </CardBody>
    </Card>
  </Col>
);

BigCalendar.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default BigCalendar;
