import { Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import CameraFlipIcon from "mdi-react/CameraFlipIcon";
import CheckIcon from "mdi-react/CheckIcon";
import firebase from "firebase/app";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import "firebase/storage";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "../../constants";
import { useParams } from "react-router-dom";
const storage = firebase.storage();
// Create a storage reference from our storage service
const storageRef = storage.ref();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App(props) {
  const { section } = useParams();
  const [toggle, setToggle] = useState(true);
  const [imgs, setImg] = useState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto");
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggleTwo = () => {
    setOpen(true);
  };

  const handleUpload = () => {
    handleToggleTwo();
    const path = `document-from-external/${moment().format(
      "DD-MM-YYYY"
    )}/${uuidv4()}.jpg`;
    const imagesRef = storageRef.child(path);
    fetch(imgs)
      .then((res) => res.blob())
      .then((blob) => {
        imagesRef.put(blob).then(async (snapshot) => {
          // ... success
          console.log("Uploaded a base64url string!");
          console.log(snapshot);

          storageRef
            .child(path)
            .getDownloadURL()
            .then(async (url) => {
              // eslint-disable-next-line
              const result = await axios.post(
                API_URL + "/user/document-from-external",
                { url, section }
              );
            });

          handleClose();
          Swal.fire({
            icon: "success",
            title: "ทำการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            confirmButtonColor: "#D7252A",
            // timer: 1500
          }).then(() => {
            setImg(null);
          });
        });
      });
  };

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    console.log("takePhoto");
    // console.log(dataUri);
    setImg(dataUri);
  }

  function handleCameraError(error) {
    console.log("handleCameraError", error);
  }

  function handleCameraStart(stream) {
    console.log("handleCameraStart");
  }

  function handleCameraStop() {
    console.log("handleCameraStop");
  }

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <Container>
      {imgs ? (
        <div>
          <Typography variant="h5" gutterBottom align="center" color="primary">
            คุณต้องการใช้รูปนี้หรือไม่
          </Typography>
          <img src={imgs} alt="preview" />
          <Grid
            container
            justifyContent="center"
            spacing={1}
            alignItems="center"
            alignContent="center"
          >
            <Grid item>
              <Button
                color="primary"
                startIcon={<CheckIcon />}
                onClick={() => handleUpload()}
                variant="contained"
              >
                ใช้รูปนี้
              </Button>
            </Grid>
            <Grid item>หรือ</Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<CameraFlipIcon />}
                onClick={() => setImg(null)}
                variant="outlined"
              >
                ถ่ายใหม่
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Typography variant="h5" gutterBottom align="center" color="primary">
            กรุณาถ่ายรูปเอกสารที่ท่านนำมาส่ง
          </Typography>
          <Camera
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
            onTakePhotoAnimationDone={(dataUri) => {
              handleTakePhotoAnimationDone(dataUri);
            }}
            onCameraError={(error) => {
              handleCameraError(error);
            }}
            idealFacingMode={
              toggle ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER
            }
            idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.97}
            isMaxResolution={true}
            isImageMirror={false}
            isSilentMode={false}
            isDisplayStartCameraError={true}
            isFullscreen={false}
            sizeFactor={1}
            onCameraStart={(stream) => {
              handleCameraStart(stream);
            }}
            onCameraStop={() => {
              handleCameraStop();
            }}
          />
          <Grid container justifyContent="center" alignContent="center">
            <Grid item>
              <Button
                color="primary"
                startIcon={<CameraFlipIcon />}
                onClick={() => handleToggle()}
                variant="contained"
              >
                สลับกล้อง
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default App;
