import React, { useEffect, useState } from "react";
import "../../database/firebase";
import firebase from "firebase/app";
import { toast } from "react-toastify";

import "firebase/firestore";

const firestore = firebase.firestore();

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      // console.log("user", user);
      if (!(user === null)) {
        const adminRef = firestore.collection("admin-users").doc(user.uid);
        adminRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              const { role } = doc.data();
              if (role >= 1) {
                const newUser = { ...user, role };
                setCurrentUser(newUser);
                setPending(false);
              } else {
                setCurrentUser(null);
                toast.success("ลงทะเบียนสำเร็จ กรุณารอการยืนยัน จากแอดมิน โดยจะมีอีเมลแจ้งเตือนไปที่ท่าน เมื่อได้รับการอนุมัต");
                setPending(false);
              }
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
              toast.success("ลงทะเบียนสำเร็จ กรุณารอการยืนยัน จากแอดมิน โดยจะมีอีเมลแจ้งเตือนไปที่ท่าน เมื่อได้รับการอนุมัต");
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      } else {
        // setCurrentUser(null);

        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return (
      <div className={`load loaded`}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path
              fill="#D7252A"
              d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
            />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
