import React from "react";
import  firebase from "firebase/app";
import { auth } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/auth';
import { Button, Typography } from "@material-ui/core";
import FileDocumentBoxMultipleIcon from 'mdi-react/FileDocumentBoxMultipleIcon'
import { useHistory } from "react-router-dom";


// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google , Facebook , Etc as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    }
    
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      return false;
    },
  }
};

const LogIn = (props) => {
  const history = useHistory()
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <Typography variant="h5" color="primary" component="div" align="center">ยินดีต้อนรับสู่ โตโยต้าขอนแก่น</Typography>
          <br />
          <Typography variant="subtitle1" color="primary" align="center">กรุณาเข้าสู่ระบบเพื่อใช้งาน</Typography>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          <Button size="small" fullWidth disableElevation color="inherit" onClick={()=> history.push("/how-to-use")} startIcon={<FileDocumentBoxMultipleIcon />} >คู่มือการใช้งาน</Button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAuth: (userEmail) => dispatch(auth({ name: userEmail })),
});

export default connect(null, mapDispatchToProps)(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
