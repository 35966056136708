 const timeRanges = [
    {label:"30 นาที" , value:30},
    {label:"45 นาที" , value:45},
    {label:"1 ชั่วโมง" , value:60},
    {label:"1 ชั่วโมง 30 นาที" , value:90},
    {label:"2 ชั่วโมง" , value:120},
    {label:"2 ชั่วโมง 30 นาที" , value:150},
    {label:"3 ชั่วโมง" , value:180},
    {label:"3 ชั่วโมง 30 นาที" , value:210},
    {label:"4 ชั่วโมง" , value:240},
    {label:"4 ชั่วโมง 30 นาที" , value:270},
    {label:"5 ชั่วโมง" , value:300},
    {label:"5 ชั่วโมง 30 นาที" , value:330},
    {label:"6 ชั่วโมง" , value:360},
    {label:"6 ชั่วโมง 30 นาที" , value:390},
    {label:"7 ชั่วโมง" , value:410},
    {label:"7 ชั่วโมง 30 นาที" , value:440},
    {label:"8 ชั่วโมง" , value:470},
    {label:"8 ชั่วโมง 30 นาที" , value:500},
]

export default timeRanges;