import React from 'react';
import {  Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import AllRoom from '../Meeting/AllRoom';
import DetailRoom from '../Meeting/DetailRoom';
import AllowEvent from '../Meeting/AllowEvent';
// import BookingRoom from '../Meeting/BookingRoom';
import PrivateRoute from './PrivateRoute'
import FreeRoute from './FreeRoute'
import LogOut from '../LogOut'
import AdminUser from '../AdminUser'
import ManageRoom from '../Meeting/ManageRoom'
import DocumentFormExternal from '../DocumentLogFromExternal'
import ViewPdf from '../ViewPdf'
import MyEvent from '../Meeting/MyEvent'
import HowToUse from '../HowToUse';
// import BookList from '../BookList';
import Stat from '../Meeting/Stat';


const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/all-room" component={AllRoom} />
      <PrivateRoute path="/detail-room/:id" component={DetailRoom} />
      <PrivateRoute path="/allow-event" component={AllowEvent} />
      <PrivateRoute path="/admin-user" component={AdminUser} />
      <PrivateRoute path="/manage-room" component={ManageRoom} />
      <PrivateRoute path="/my-event" component={MyEvent} />
      <PrivateRoute path="/stat" component={Stat} />
      {/* <PrivateRoute path="/book-list" component={BookList} /> */}
      <PrivateRoute path="/logout" component={LogOut} />
      {/* <PrivateRoute path="/a" component={BookingRoom} /> */}
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <FreeRoute exact path="/" component={LogIn} />
        <FreeRoute exact path="/login" component={LogIn} />
        <Route exact path="/document-from-external/:section" component={DocumentFormExternal} />
        <Route exact path="/pdf" component={ViewPdf} />
        <Route exact path="/how-to-use" component={HowToUse} />
        <PrivateRoute path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
