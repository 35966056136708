/* eslint-disable react/no-children-prop */
import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Card, CardBody, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Chip, Grid, Typography } from "@material-ui/core";
import CalendarClockIcon from "mdi-react/CalendarClockIcon";
// import moment from "moment";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import moment from "moment";
// import { DatePicker } from "@material-ui/pickers";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import { forwardRef } from "react";
import th from "date-fns/locale/th";
import timeRange from "./timeRange";
import ProjectorScreenIcon from "mdi-react/TelevisionIcon";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import HumanMaleBoardIcon from "mdi-react/CameraIcon";
import Laptop from "mdi-react/LaptopIcon";
import WifiIcon from "mdi-react/WifiIcon";
import VolumeHighIcon from "mdi-react/VolumeHighIcon";
// import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import th from 'moment/locale/th'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

registerLocale("th", th);

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    margin: theme.spacing(1),
  },
  media: {
    height: 140,
  },
  marginY: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  icon: {
    verticalAlign: "text-bottom",
  },
  chip: {
    margin: theme.spacing(0.4),
    "& .MuiChip-labelSmall": {
      padding: theme.spacing(1),
    },
  },
}));

const renderTextField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  children,
  select,
  multiline,
}) => (
  <div style={{ marginBottom: "1em" }}>
    {touched &&
      ((error && (
        <span className="bg-danger p-2 text-white mb-0 float-right">
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
    <TextField
      color="secondary"
      variant="outlined"
      type={type ? type : "text"}
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      fullWidth
      select={select}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: "",
  meta: null,
  select: false,
  children: [],
};


const renderRishText = ({
  input,
  label,
  meta: { touched, error, warning },
}) => (
  <div style={{ marginBottom: "1em" }}>
    {touched &&
      ((error && (
        <span className="bg-danger p-2 text-white mb-0 float-right">
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
        {label}
    <ReactQuill
      theme="snow"
      value={input.value}
      onChange={(e) => {
        // e.preventDefault();
        input.onChange(e);
      }}
      // style={{ minHeight: "300px" }}
    />
  </div>
);

const ExampleCustomInput = forwardRef(
  ({ value, onClick, label, val, meta: { touched, error, warning } }, ref) => {
    // console.log(val);
    return (
      <TextField
        color="secondary"
        variant="outlined"
        label={label}
        error={touched && error}
        value={val ? moment(val).format("DD/MM/YY เวลา HH:mm") : ""}
        ref={ref}
        fullWidth
        onClick={onClick}
        onFocus={onClick}
      />
    );
  }
);

const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() <= selectedDate.getTime();
};
const filterPassedDate = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  currentDate.setHours(0, 0, 0, 0);
  selectedDate.setHours(0, 0, 0, 0);

  return currentDate <= selectedDate;
};

const renderDateTimePicker = ({
  input,
  label,
  meta: { touched, error, warning },
}) => (
  <div style={{ marginBottom: "1em" }}>
    {touched &&
      ((error && (
        <span className="bg-danger p-2 text-white mb-0 float-right">
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
    <ReactDatePicker
      {...input}
      timeFormat="HH:mm"
      customInput={
        <ExampleCustomInput
          label={label}
          val={input.value}
          meta={{ touched, error, warning }}
        />
      }
      selected={input.value}
      timeCaption="เวลา"
      timeIntervals={15}
      filterTime={filterPassedTime}
      filterDate={filterPassedDate}
      locale="th"
      onChange={(date) => {
        input.onChange(date);
      }}
      showTimeSelect
      withPortal
    />
  </div>
);

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    "title",
    "priority",
    "timeStart",
    "duration",
    // "detail",
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "จำเป็นต้องกรอกช่องนี้";
    }
  });
  // if (
  //   values.email &&
  //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  // ) {
  //   errors.email = 'Invalid email address'
  // }
  return errors;
};

const AnimatedLine = ({ handleSubmit, reset, onCancle, submitting, data }) => {
  // const classes = useStyles();

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    const result = await handleSubmit(e);
    if (!result) {
      onCancle("bottom", false);
    }
  };
  const classes = useStyles();

  return (
    <Col md={12} lg={12}>
      <Card>
        <BlockUi tag="div" blocking={submitting} color="#D7252A">
          <CardBody>
            {/* <div className="card__title">
            <h5 className="bold-text">แบบฟอร์มการจองห้องประชุม</h5>
            <h3 className="subhead">กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน</h3>
          </div> */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="h6">แบบฟอร์มการจองห้องประชุม</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  ข้อที่มีเครื่องหมาย * หมายถึงจำเป็นต้องระบุ
                  กรุณากรอกข้อมูลให้ถูกต้อง
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant="subtitle1" color="textPrimary">
                  อุปกรณ์ที่มีในห้อง
                </Typography>
                <div>
                  {data.facility.monitor && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={"TV"}
                      color="primary"
                      className={classes.chip}
                      icon={<ProjectorScreenIcon />}
                    />
                  )}
                  {data.facility.mic && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={"ไมโครโฟน"}
                      color="primary"
                      className={classes.chip}
                      icon={<MicrophoneIcon />}
                    />
                  )}
                  {data.facility.cam && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={"กล้อง"}
                      color="primary"
                      className={classes.chip}
                      icon={<HumanMaleBoardIcon />}
                    />
                  )}
                  {data.facility.control && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={"เครื่องควบคุม"}
                      color="primary"
                      className={classes.chip}
                      icon={<Laptop />}
                    />
                  )}
                  {data.facility.wifi && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={"WIFI"}
                      color="primary"
                      className={classes.chip}
                      icon={<WifiIcon />}
                    />
                  )}
                  {data.facility.speaker && (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={"ลำโพง"}
                      color="primary"
                      className={classes.chip}
                      icon={<VolumeHighIcon />}
                    />
                  )}
                </div>
              </Grid>
            </Grid>

            <br />
            <form onSubmit={handleSubmit2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="priority"
                    component={renderTextField}
                    label="ประเภทการประชุม *"
                    select
                  >
                    <MenuItem className="material-form__option" value="hi">
                      ประชุมผู้บริหาร
                    </MenuItem>
                    <MenuItem className="material-form__option" value="md">
                      ประชุมภายใน
                    </MenuItem>
                    <MenuItem className="material-form__option" value="lo">
                      ประชุมภายนอก
                    </MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="title"
                    component={renderTextField}
                    label="ชื่อการประชุม *"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="timeStart"
                    label="เวลาเริ่มประชุม *"
                    dateFormat="YYYY-MM-DD[T]HH:MM"
                    component={renderDateTimePicker}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="duration"
                    component={renderTextField}
                    label="ระยะเวลาการประชุม *"
                    select
                  >
                    {timeRange.map((thisdata, key) => (
                      <MenuItem
                        className="material-form__option"
                        key={key}
                        value={thisdata.value}
                      >
                        {thisdata.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="duration"
                    component={renderTextField}
                    label="ระยะเวลาการประชุม (นาที)"
                    className={classes.root}
                    type="number"
                  />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Field
                    name="detail"
                    component={renderRishText}
                    label="รายละเอียด - วาระการประชุม"
                    // multiline
                    // rowsMax="4"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Field
                    name="request"
                    component={renderTextField}
                    label="คำขอเพิ่มเติม"
                    // multiline
                    // rowsMax="4"
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Button
                    fullWidth
                    color="default"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      onCancle("bottom", false);
                    }}
                  >
                    ยกเลิก
                  </Button>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Button
                    fullWidth
                    type="submit"
                    // onClick={()=>{
                    //   onCancle("bottom", false);
                    // }}
                    startIcon={<CalendarClockIcon />}
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    จองห้องประชุม
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardBody>
        </BlockUi>
      </Card>
    </Col>
  );
};

AnimatedLine.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "booking_meeting_room", // a unique identifier for this form
  validate,
})(AnimatedLine);
