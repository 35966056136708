import axios from "axios";
import firebase from "firebase/app";
import Swal from "sweetalert2";
import config from "../../../constants";

const OnApprove = async (rowData, handleToggle, handleClose) => {
  handleToggle();
  const idToken = await firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true);

  const result = await axios.post(
    config.API_URL + "/user/reject-meeting-event",
    {
      idToken,
      data: rowData,
    }
  );
  handleClose();
  if (result.data.status) {
    Swal.fire({
      title: "สำเร็จ",
      text: "ยกเลิกคำขอเรียบร้อย",
      icon: "success",
      confirmButtonText: "ตกลง",
      confirmButtonColor: "#D7252A",
    });
    return true;
  } else {
    Swal.fire({
      title: "เกิดข้อผิดพลาด",
      text: "มีข้อผิดพลาดบางอย่างเกิดขึ้น กรุณาลองใหม่อีกครั้ง",
      icon: "error",
      confirmButtonText: "ตกลง",
      confirmButtonColor: "#D7252A",
    });
    return false;
  }

};

export default OnApprove;
