import React, { useEffect, useState } from "react";
// import {Link} from 'react-router-dom';
import MaterialTable from "material-table";
import { Container } from "reactstrap";

import Edit from "mdi-react/EyeIcon";
import CloseThickIcon from "mdi-react/CloseThickIcon";
import CheckBoldIcon from "mdi-react/CheckBoldIcon";
import OnOpenModal from "./ModalInfo";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../App/firebaseAuth";
import { useContext } from "react";

import firebase from "firebase/app";

import { localeTh, tableIcons } from "./configTable";

// import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "./style.css";
import moment from "moment";
import OnApprove from "./OnApprove";
import OnReject from "./OnReject";
import { useHistory } from "react-router-dom";
// import { Avatar } from "@material-ui/core";

const firestore = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AllPayment = (props) => {
  const [tableData, setTableData] = useState();
  const history = useHistory();
  const currentUser = useContext(AuthContext);
  const tableName = "คำขอจองห้องประชุม";
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // const [isOpen, setIsOpen] = useState();
  // const [titleImg, setTitleImg] = useState();

  useEffect(() => {
    if (currentUser.currentUser.role <= 1) {
      history.push("/dashboard");
    }
    // firebase.auth().onAuthStateChanged((user) => {
    const now = moment().unix();

    const userRef = firestore
      .collection("booking-room")
      .where("end", ">=", now);
    userRef
      .get()
      .then(function (querySnapshot) {
        let pmData = [];
        querySnapshot.forEach(function (pmDoc) {
          // doc.data() is never undefined for query doc snapshots
          if (pmDoc.exists) {
            const currentProduct = {
              id: pmDoc.id,
              ...pmDoc.data(),
            };
            pmData = [...pmData, currentProduct];
          }
        });
        setTableData(pmData);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    // });
  }, [currentUser.currentUser.role, history]);

  return (
    <div>
      <Container className="text-center">
        <Backdrop
          className={classes.backdrop}
          open={open}
          //   onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <MaterialTable
          columns={[
            { title: "ห้องประชุม", field: "roomName" },
            { title: "หัวข้อการประชุม", field: "title" },
            // { title: "รายละเอียด", field: "detail"},
            { title: "ผู้จอง", field: "displayName" },
            // { title: "อีเมลล์ผู้จอง", field: "email" },
            {
              title: "เวลาเริ่มประชุม",
              field: "start",
              render: (rowData) =>
                `${
                  moment
                    .unix(rowData.start)
                    .isSame(moment.unix(rowData.end), "day")
                    ? `${moment
                        .unix(rowData.start)
                        .format("DD/MM/yy [เวลา] HH:mm")}  - ${moment
                        .unix(rowData.end)
                        .format("HH:mm")} น.`
                    : `${moment
                        .unix(rowData.start)
                        .format("DD/MM/yy HH:mm")} น. - ${moment
                        .unix(rowData.end)
                        .format("DD/MM/yy HH:mm")} น.`
                }`,
            },
            { title: "เวลาประชุม (นาที)", field: "duration" },
            {
              title: "สถานะ",
              field: "approved",
              render: (rowData) => (
                <div>
                  {rowData.approved
                    ? "อนุมัติแล้ว"
                    : rowData.rejected
                    ? "ไม่อนุมัติ"
                    : "กำลังตรวจสอบ"}
                </div>
              ),
            },
          ]}
          data={tableData}
          title={tableName}
          icons={tableIcons}
          options={{
            // showTitle: false,
            // exportButton: true,
            headerStyle: {
              backgroundColor: "#D7252A",
              color: "#ffffff",
              // padding: "3px",
            },
            rowStyle: (rowData) =>
              rowData.approved
                ? { backgroundColor: "#e3ffe0", textAlign: "center" }
                : rowData.rejected
                ? { backgroundColor: "#ffe5e5", textAlign: "center" }
                : { backgroundColor: "#fffec2", textAlign: "center" },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            // sorting: false,
            // grouping: true,
            actionsColumnIndex: -1,
            // tableLayout: "fixed",
          }}
          actions={[
            (rowData) => ({
              icon: () => <CheckBoldIcon />,
              tooltip: "อนุมัติการจอง",
              disabled: rowData.approved,
              onClick: (event, rowData) => {
                OnApprove(rowData, handleToggle, handleClose, tableData).then(
                  (result) => {
                    if (result) {
                      const dataUpdate = [...tableData];
                      const index = rowData.tableData.id;
                      dataUpdate[index].approved = true;
                      dataUpdate[index].rejected = false;
                      setTableData([...dataUpdate]);
                    }
                  }
                );
              },
              // props.history.push("/user-detail/" + rowData.id),
            }),
            (rowData) => ({
              icon: () => <CloseThickIcon />,
              tooltip: "ยกเลิกการจอง",
              disabled: rowData.rejected,
              onClick: (event, rowData) => {
                OnReject(rowData, handleToggle, handleClose).then((result) => {
                  if (result) {
                    const dataUpdate = [...tableData];
                    const index = rowData.tableData.id;
                    dataUpdate[index].approved = false;
                    dataUpdate[index].rejected = true;
                    setTableData([...dataUpdate]);
                  }
                });
              },
            }),
            {
              icon: () => <Edit />,
              tooltip: "ดูข้อมูล",
              onClick: (event, rowData) => OnOpenModal(rowData),
              // props.history.push("/user-detail/" + rowData.id),
            },
          ]}
          localization={localeTh}
        />
      </Container>
    </div>
  );
};

export default AllPayment;
