import React from "react";
import {
  Card,
  CardBody,
  Col,
  // Button,
  ButtonToolbar,
  ButtonGroup,
  Container,
} from "reactstrap";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import showResults from "../../../BookingRoom/Show";
import AnimatedLineForm from "../../../BookingRoom/components/AnimatedLineForm";
// import moment from "moment";
import { Button } from "@material-ui/core";
// import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  list: {
    width: "80vw",
    // borderRadius: "50px"
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    borderRadius: "50px 50px 0 0",
  },
});

const EventLabels = ({roomId , event , data}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log(event);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onCancle = (anchor) => {
    setState({ ...state, [anchor]: false });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container>
        <AnimatedLineForm
          onSubmit={showResults}
          onCancle={onCancle}
          // event={event}
          initialValues={{   roomId , event , data }}
          data={data}
          // initialValues={{ timeStart: moment().toDate() , roomId , event , data }}
          // initialValues={{ timeStart: moment().format("YYYY-MM-DD[T]HH:MM") , roomId , event , data }}
        />
      </Container>
    </div>
  );

  return (
    <Col md={12} lg={12} xl={3}>
      <Card className="card--not-full-height">
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">ประเภทการประชุม</h5>
          </div>
          <p className="typography-message">
            <span className="calendar-label calendar-label--red" /> ประชุมผู้บริหาร
          </p>
          <p className="typography-message">
            <span className="calendar-label" style={{backgroundColor:"#3074AC"}} /> ประชุมภายใน
          </p>
          <p className="typography-message">
            <span className="calendar-label calendar-label--blue" />{" "}
            ประชุมภายนอก
          </p>
          <ButtonToolbar>
            <ButtonGroup className="btn-group--justified">
              <Button color="primary" fullWidth variant="contained" onClick={toggleDrawer("bottom", true)}>
                จองห้องนี้
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          <Drawer
            anchor={"bottom"}
            open={state["bottom"]}
            // onClose={toggleDrawer("bottom", false)}
            PaperProps={{ className: classes.drawer }}
          >
            {list("bottom")}
          </Drawer>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EventLabels;
