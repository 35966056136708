/* eslint-disable react/no-children-prop */
import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Card, CardBody, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, Grid,  Typography } from "@material-ui/core";
import CalendarClockIcon from "mdi-react/HomeAssistantIcon";
// import moment from "moment";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
// import moment from "moment";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Divider } from "@material-ui/core";



const renderCheckBox = ({
  input,
  label,
  meta: { touched, error, warning },
  children,
}) => (
  <div style={{ marginBottom: "1em" }}>
    {touched &&
      ((error && (
        <span className="bg-danger p-2 text-white mb-0 float-right">
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
    <FormControlLabel
      control={<Checkbox />}
      label={label}
      checked={input.value ? true : false}
      onChange={input.onChange}
    />
  </div>
);

const renderTextField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  children,
  select,
  multiline,
}) => (
  <div style={{ marginBottom: "1em" }}>
    {touched &&
      ((error && (
        <span className="bg-danger p-2 text-white mb-0 float-right">
          {error}
        </span>
      )) ||
        (warning && <span>{warning}</span>))}
    <TextField
      color="secondary"
      variant="outlined"
      type={type ? type : "text"}
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      fullWidth
      select={select}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  label: "",
  meta: null,
  select: false,
  children: [],
};



const validate = (values) => {
  const errors = {};
  const requiredFields = ["name", "location", "capacity", ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "จำเป็นต้องกรอกช่องนี้";
    }
  });
  // if (
  //   values.email &&
  //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  // ) {
  //   errors.email = 'Invalid email address'
  // }
  return errors;
};

const AnimatedLine = ({ handleSubmit, reset, onCancle, submitting , isUpdate }) => {
  // const classes = useStyles();

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    const result = await handleSubmit(e);
    if (!result) {
      onCancle("bottom", false);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <BlockUi tag="div" blocking={submitting} color="#D7252A">
          <CardBody>
            {/* <div className="card__title">
            <h5 className="bold-text">แบบฟอร์มการจองห้องประชุม</h5>
            <h3 className="subhead">กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน</h3>
          </div> */}
            <Typography variant="h6">แบบฟอร์ม{isUpdate?"แก้ไข":"เพิ่ม"}ห้องประชุม</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน
            </Typography>
            <br />
            <form onSubmit={handleSubmit2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="name"
                    component={renderTextField}
                    label="ชื่อห้องประชุม"
                    // type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="location"
                    component={renderTextField}
                    label="สถานที่"
                    // type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="capacity"
                    component={renderTextField}
                    label="ความจุ (จำนวนคน)"
                    // type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Field
                    name="isShow"
                    component={renderTextField}
                    label="อนุญาติให้จองไหม"
                    select
                  >
                    <MenuItem className="material-form__option" value={true}>
                      อนุญาติ
                    </MenuItem>
                    <MenuItem className="material-form__option" value={false}>
                      ไม่อนุญาติให้จอง
                    </MenuItem>
                  </Field>
                </Grid>

                <Divider />
                <Typography variant="subtitle1">สิ่งอำนวยความสะดวก</Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormGroup row>
                    <Field
                      name="facility.cam"
                      component={renderCheckBox}
                      label="กล้อง"
                    />
                    <Field
                      name="facility.control"
                      component={renderCheckBox}
                      label="อุปกรณ์ควบคุม"
                    />
                    <Field
                      name="facility.wifi"
                      component={renderCheckBox}
                      label="wifi"
                    />
                    <Field
                      name="facility.mic"
                      component={renderCheckBox}
                      label="ไมโครโฟน"
                    />
                    <Field
                      name="facility.speaker"
                      component={renderCheckBox}
                      label="ลำโพง"
                    />
                    <Field
                      name="facility.monitor"
                      component={renderCheckBox}
                      label="ลำโพง"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Button
                    fullWidth
                    color="default"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      onCancle("bottom", false);
                    }}
                  >
                    ยกเลิก
                  </Button>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  <Button
                    fullWidth
                    type="submit"
                    // onClick={()=>{
                    //   onCancle("bottom", false);
                    // }}
                    startIcon={<CalendarClockIcon />}
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    {isUpdate?"แก้ไข":"เพิ่ม"}ห้องประชุมใหม่
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardBody>
        </BlockUi>
      </Card>
    </Col>
  );
};

AnimatedLine.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "add_new_meeting_room", // a unique identifier for this form
  validate,
})(AnimatedLine);
