import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { Container } from "reactstrap";
// import AddBox from "mdi-react/AddBoxIcon";
import Edit from "mdi-react/AccountPlusIcon";
import Edit2 from "mdi-react/AccountMinusIcon";

import firebase from "../../database/firebase";
import "firebase/firestore";
// import { Button } from "reactstrap";
import { localeTh, tableIcons } from "../Meeting/AllowEvent/configTable";
// import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import { toast } from "react-toastify";
import { API_URL, roleStr } from "../../constants";
import { AuthContext } from "../App/firebaseAuth";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";

const firestore = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AllPayment = (props) => {
  const [tableData, setTableData] = useState();
  const tableName = "จัดการผู้ใช้ในระบบ";
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const currentUser = useContext(AuthContext);
  const history = useHistory();
  // const [isOpen, setIsOpen] = useState();
  // const [titleImg, setTitleImg] = useState();

  useEffect(() => {
    if (currentUser.currentUser.role <= 1) {
      history.push("/dashboard");
    }
    firebase.auth().onAuthStateChanged((user) => {
      const userRef = firestore.collection("admin-users").where("role", "<", 3);
      userRef
        .get()
        .then(function (querySnapshot) {
          let pmData = [];
          querySnapshot.forEach(function (pmDoc) {
            // doc.data() is never undefined for query doc snapshots
            if (pmDoc.exists) {
              const currentProduct = {
                id: pmDoc.id,
                ...pmDoc.data(),
              };
              pmData = [...pmData, currentProduct];
            }
          });
          setTableData(pmData);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });
  }, [currentUser.currentUser.role, history]);

  return (
    <div>
      <Backdrop
          className={classes.backdrop}
          open={open}
          //   onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      <Container className="text-center">
        {/* <h3 className="mb-2 text-center">{tableName}</h3> */}
        <MaterialTable
          columns={[
            { title: "ชื่อ", field: "displayName" },
            { title: "อีเมล", field: "email" },
            { title: "สิทธิ์", render: (rowData) => roleStr[rowData.role] },
          ]}
          data={tableData}
          title={tableName}
          icons={tableIcons}
          options={{
            // exportButton: true,
            // showTitle: false,
            headerStyle: {
              backgroundColor: "#D7252A",
              color: "#ffffff",
              // padding: "3px",
            },
            // rowStyle: (rowData) =>
            //   rowData.paymentStatus == "Paid"
            //     ? { backgroundColor: "#e3ffe0" }
            //     : { backgroundColor: "#ffe5e5" },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            sorting: false,
            // grouping: true,
            actionsColumnIndex: -1,
            // tableLayout: "fixed",
          }}
          actions={[
            (rowData) => ({
              icon: () => <Edit />,
              tooltip: "เพิ่มสิทธิ์",
              disabled:
                rowData.role >= 2 || rowData.id === currentUser.currentUser.uid,
              onClick: (event, rowData) => {
                new Promise(async (resolve, reject) => {
                  handleToggle()
                  if (rowData.role >= 2) {
                    toast.warning(
                      "ไม่สามารถเพิ่มได้ เนื่องจากเป็นสิทธิ์สูงสุดแล้ว"
                    );
                    reject();
                  } else {
                    const idToken = await firebase
                      .auth()
                      .currentUser.getIdToken(/* forceRefresh */ true);
                    const result = await axios.post(
                      API_URL + "/user/edit-user",
                      {
                        idToken,
                        data: {
                          ...rowData,
                          role: rowData.role + 1,
                        },
                      }
                    );
                    handleClose()
                    if (result.data.status) {
                      const dataDelete = [...tableData];
                      const index = rowData.tableData.id;
                      dataDelete[index].role = dataDelete[index].role + 1;
                      toast.success("แต่งตั้งเรียบร้อยแล้ว");
                      setTableData([...dataDelete]);
                      resolve();
                    } else {
                      toast.warn("เกิดข้อผิดพลาด")
                      reject()
                    }
                  }
                });
              },
            }),
            (rowData) => ({
              icon: () => <Edit2 />,
              tooltip: "ลดสิทธิ์",
              disabled:
                rowData.role <= 0 || rowData.id === currentUser.currentUser.uid,
              onClick: (event, rowData) => {
                new Promise(async(resolve, reject) => {
                  if (rowData.role <= 0) {
                    toast.warning("ไม่สามารถลดได้");
                    reject();
                  } else {
                    const idToken = await firebase
                      .auth()
                      .currentUser.getIdToken(/* forceRefresh */ true);
                    const result = await axios.post(
                      API_URL + "/user/edit-user",
                      {
                        idToken,
                        data: {
                          ...rowData,
                          role: rowData.role - 1,
                        },
                      }
                    );
                    if (result.data.status) {
                      const dataDelete = [...tableData];
                      const index = rowData.tableData.id;
                      dataDelete[index].role = dataDelete[index].role - 1;
                      toast.success("แต่งตั้งเรียบร้อยแล้ว");
                      setTableData([...dataDelete]);
                      resolve();
                    } else {
                      toast.warn("เกิดข้อผิดพลาด")
                      reject()
                    }
                  }
                });
              },
            }),
          ]}
          localization={localeTh}
          // editable={{
          //   onRowDelete: (oldData) =>
          //     new Promise((resolve, reject) => {
          //       const userRef = firestore
          //         .collection("admin-users")
          //         .doc(oldData.id);
          //       userRef
          //         .delete()
          //         .then(function () {
          //           const dataDelete = [...tableData];
          //           const index = oldData.tableData.id;
          //           dataDelete.splice(index, 1);
          //           setTableData([...dataDelete]);

          //           resolve();
          //         })
          //         .catch(function (error) {
          //           reject();
          //         });
          //     }),
          // }}
        />
      </Container>
    </div>
  );
};

export default AllPayment;
