import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { AuthContext } from '../../App/firebaseAuth'
import { useContext } from 'react';
// import { auth } from '../../../redux/actions/authActions';
// import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick, changeToDark, changeToLight }) => {
  const currentUser = useContext(AuthContext)
  const {role} = currentUser.currentUser
  const handleHideSidebar = () => {
    onClick();
  };

  const isAdmin = () => role >=2
  

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {/* <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={handleHideSidebar} /> */}
        <SidebarLink title="ห้องประชุมทั้งหมด" icon="apartment" route="/all-room" onClick={handleHideSidebar} />
        <SidebarLink title="คำขอของฉัน" icon="enter" route="/my-event" onClick={handleHideSidebar} />
        <hr />
        { isAdmin() && <SidebarLink title="คำขอจองห้องประชุม" icon="inbox" route="/allow-event" onClick={handleHideSidebar} />}
        { isAdmin() && <SidebarLink title="จัดการผู้ใช้งาน" icon="users" route="/admin-user" onClick={handleHideSidebar} />}
        { isAdmin() && <SidebarLink title="จัดการห้องประชุม" icon="select" route="/manage-room" onClick={handleHideSidebar} />}
        { isAdmin() && <SidebarLink title="สถิติการจองห้องประชุม" icon="history" route="/stat" onClick={handleHideSidebar} />}
        { isAdmin() && <hr />}
        <SidebarLink title="คู่มือการใช้งาน" icon="graduation-hat" route="/how-to-use" onClick={handleHideSidebar} />
        {/* <SidebarCategory title="Layout" icon="layers">
          <button type="button" className="sidebar__link" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory> */}
      </ul>
      {/* <ul className="sidebar__block">
        <SidebarCategory title="Example Pages" icon="diamond">
          <SidebarLink title="Page one" route="/pages/one" onClick={handleHideSidebar} />
          <SidebarLink title="Page two" route="/pages/two" onClick={handleHideSidebar} />
        </SidebarCategory>
      </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
