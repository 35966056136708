import React, { useState } from "react";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from "moment";
import "moment/locale/th";
import Swal from "sweetalert2";
// moment.locale("th")
// import 'moment/locale/fr'  // without this line it didn't work
// let abcde = moment.locale('th')
const localizer = momentLocalizer(moment);

// const DragAndDropCalendar = withDragAndDrop(Calendar);

const formats = {
  dayFormat: (date, culture) => localizer.format(date, "ddd [ที่] DD", culture),
  dayHeaderFormat: (date, culture) =>
    localizer.format(date, "[วัน]dddd [ที่] DD MMMM", culture),
  weekDayFormat: (date, culture) => localizer.format(date, "[ที่]DD", culture),
  agendaDateFormat: (date, culture) => localizer.format(date, "DD MMM", culture),
  dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
    localizer.format(start,"DD MMMM" , culture) + ' — ' +
    localizer.format(end, "DD MMMM", culture)
};

const CalendarComponent = ({ small, events: propEvents, dir }) => {
  // eslint-disable-next-line
  const [events, setEvents] = useState(propEvents);

  const eventStyleGetter = (event) => {
    let color;

    switch (event.priority) {
      case "hi":
        color = "#fa697d";
        break;

      case "md":
        color = "#4ce1b6";
        break;

      case "lo":
        color = "#70bbfd";
        break;
      default:
        color = "$color-additional";
        break;
    }

    const style = {
      backgroundColor: color,
      border: "none",
    };

    return {
      style,
    };
  };

  // function EventAgenda({ event }) {
  //   return (
  //     <span>
  //       <em style={{ color: "magenta" }}>{event.title}</em>
  //       <p>{event.desc}</p>
  //     </span>
  //   );
  // }

  const customDayPropGetter = (date) => {
    if (
      date.getDate() === moment().date() &&
      date.getMonth() === moment().month()
    )
      return {
        className: "special-day",
        style: {
          // border: "solid 3px " + (date.getDate() === 7 ? "#faa" : "#afa"),
          backgroundColor: "#f2faff",
        },
      };
    else return {};
  };

  // const customSlotPropGetter = (date) => {
  //   if (date.getDate() === 7 || date.getDate() === 15)
  //     return {
  //       className: "special-day",
  //     };
  //   else return {};
  // };
  let minCalendar = new Date();
  let maxCalendar = new Date();
  minCalendar.setHours(8);
  minCalendar.setMinutes(0);
  maxCalendar.setHours(20);
  maxCalendar.setMinutes(0);

  return (
    <div className={`calendar${small ? " calendar--small" : ""}`}>
      <Calendar
        rtl={dir === "rtl"}
        localizer={localizer}
        events={events}
        views={["month", "week", "day", "agenda"]}
        defaultView={"week"}
        scrollToTime={new Date()}
        min={minCalendar}
        max={maxCalendar}
        popup
        formats={formats}
        step={60}
        timeslots={1}
        onSelectEvent={(e) => {
          console.log(e);
          Swal.fire({
            title: e.title,
            // text: e.detail,
            confirmButtonColor: "#D7252A",
          });
        }}
        showMultiDayTimes
        defaultDate={new Date(moment())}
        // defaultDate={new Date(moment().format('MMMM DD YY'))}
        eventPropGetter={eventStyleGetter}
        dayPropGetter={customDayPropGetter}
        // slotPropGetter={customSlotPropGetter}
        // components={{
        //   event: Event,
        //   agenda: {
        //     event: EventAgenda,
        //   },
        // }}
        messages={{
          previous: <span className="lnr lnr-chevron-left" />,
          next: <span className="lnr lnr-chevron-right" />,
          today: <span className="lnr lnr-calendar-full" />,
          month: "รายเดือน",
          week: "รายสัปดาห์",
          day: "รายวัน",
          date: "วันที่",
          time: "เวลา",
          event: "หัวข้อ"
          // agenda: "เดือน",
        }}
      />
    </div>
  );
};

CalendarComponent.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      allDay: PropTypes.bool,
      start: PropTypes.instanceOf(Date),
      end: PropTypes.instanceOf(Date),
      priority: PropTypes.string,
    })
  ).isRequired,
  small: PropTypes.bool,
  dir: PropTypes.string,
};

CalendarComponent.defaultProps = {
  small: false,
  dir: "",
};

export default CalendarComponent;
