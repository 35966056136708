import React from 'react'
import firebase from 'firebase/app'
// import { useHistory } from 'react-router-dom';
// import { AuthProvider } from '../App/firebaseAuth'
// import { useContext } from 'react';
import { useEffect } from 'react';

export default function LogOut() {
    // const history = useHistory()
    // const auth = useContext(AuthProvider)
    useEffect(()=>{
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        window.location.reload()
        // auth.setCurrentUser(null)
        // history.push('/')
      }).catch((error) => {
        console.log(error)
        // An error happened.
      });
    },[])
    return (
        <div>
            
        </div>
    )
}
