import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import CardActions from "@material-ui/core/CardActions";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Chip, Divider } from "@material-ui/core";
// import SeatIcon from "mdi-react/SeatIcon";
import ProjectorScreenIcon from "mdi-react/TelevisionIcon";
import MicrophoneIcon from "mdi-react/MicrophoneIcon";
import HumanMaleBoardIcon from "mdi-react/CameraIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import AccountGroupIcon from "mdi-react/AccountGroupIcon";
import Laptop from "mdi-react/LaptopIcon";
import WifiIcon from "mdi-react/WifiIcon";
import VolumeHighIcon from "mdi-react/VolumeHighIcon";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    margin: theme.spacing(1),
  },
  media: {
    height: 140,
  },
  marginY: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  icon: {
    verticalAlign: "text-bottom"
  },
  chip: {
    margin: theme.spacing(0.4),
    "& .MuiChip-labelSmall": {
      padding: theme.spacing(1),
    },
  },
}));

export default function MediaCard({ room }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          history.push("/detail-room/" + room.id);
        }}
      >
        <CardMedia
          className={classes.media}
          image={room.img?room.img:"https://d2e5ushqwiltxm.cloudfront.net/wp-content/uploads/sites/107/2019/04/23031158/Salon1_UShape-Hero-Shot.jpg"}
          title="Card room picture"
        />
        <CardContent>
          <Typography gutterBottom color="primary" variant="h5" component="h2">
            {room.name}
          </Typography>

          <Typography variant="subtitle1" >
            <MapMarkerIcon fontSize="small" className={classes.icon} />
            {" "}{room.location}
          </Typography>
          <Typography variant="subtitle1">
            <AccountGroupIcon fontSize="small" className={classes.icon} />
            {` ${room.capacity} คน`}
          </Typography>

          <Divider className={classes.marginY} />
          <Typography gutterBottom variant="subtitle1">
            สิ่งอำนวยความสะดวก
          </Typography>
          <div style={{height:"4em"}}>
            {room.facility.monitor && (
              <Chip
                size="small"
                variant="outlined"
                label={"TV"}
                color="primary"
                className={classes.chip}
                icon={<ProjectorScreenIcon />}
              />
            )}
            {room.facility.mic && (
              <Chip
                size="small"
                variant="outlined"
                label={"ไมโครโฟน"}
                color="primary"
                className={classes.chip}
                icon={<MicrophoneIcon />}
              />
            )}
            {room.facility.cam && (
              <Chip
                size="small"
                variant="outlined"
                label={"กล้อง"}
                color="primary"
                className={classes.chip}
                icon={<HumanMaleBoardIcon />}
              />
            )}
            {room.facility.control && (
              <Chip
                size="small"
                variant="outlined"
                label={"เครื่องควบคุม"}
                color="primary"
                className={classes.chip}
                icon={<Laptop />}
              />
            )}
            {room.facility.wifi && (
              <Chip
                size="small"
                variant="outlined"
                label={"WIFI"}
                color="primary"
                className={classes.chip}
                icon={<WifiIcon />}
              />
            )}
            {room.facility.speaker && (
              <Chip
                size="small"
                variant="outlined"
                label={"ลำโพง"}
                color="primary"
                className={classes.chip}
                icon={<VolumeHighIcon />}
              />
            )}
          </div>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}
