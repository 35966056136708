import { Button, Container } from "@material-ui/core";
import React from "react";
import ArrowLeftCircleIcon from 'mdi-react/ArrowLeftCircleIcon'
import { useHistory } from "react-router-dom";

export default function HowToUse() {
    const history = useHistory();
  return (
    <div>
      <Container>
        <div>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 0,
              paddingTop: "56.2500%",
              paddingBottom: 48,
              boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
              marginTop: "1.6em",
              marginBottom: "0.9em",
              overflow: "hidden",
              borderRadius: 8,
              willChange: "transform",
            }}
          >
            <iframe
              title="คู่มือการใช้งาน ระบบจองห้องประชุม"
              loading="lazy"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                border: "none",
                padding: 0,
                margin: 0,
              }}
              src="https://www.canva.com/design/DAEnny67oAM/view?embed"
            ></iframe>
          </div>
        </div>
        <Button color="primary" fullWidth variant="contained" startIcon={<ArrowLeftCircleIcon />} onClick={()=> history.push("/")} >ย้อนกลับ</Button>
      </Container>
    </div>
  );
}
