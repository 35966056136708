import React, { useEffect, useState } from "react";
// import {Link} from 'react-router-dom';
import MaterialTable from "material-table";
import { Container } from "reactstrap";

import Edit from "mdi-react/EyeIcon";
import OnOpenModal from "../AllowEvent/ModalInfo";
import { AuthContext } from '../../App/firebaseAuth'
import { useContext } from 'react';

import firebase from "firebase/app";

import { localeTh, tableIcons } from "../AllowEvent/configTable";


import moment from "moment";
import { useHistory } from "react-router-dom";
// import { Avatar } from "@material-ui/core";

const firestore = firebase.firestore();

const AllPayment = (props) => {
  const [tableData, setTableData] = useState();
  const history = useHistory()
  const currentUser = useContext(AuthContext)
  const tableName = "คำขอจองห้องประชุมของฉัน";


  useEffect(() => {
      const now = moment().unix()
      
      const userRef = firestore.collection("booking-room").where("end",">=",now).where("uid" , "==" ,currentUser.currentUser.uid );
      userRef
        .get()
        .then(function (querySnapshot) {
          let pmData = [];
          querySnapshot.forEach(function (pmDoc) {
            // doc.data() is never undefined for query doc snapshots
            if (pmDoc.exists) {
              const currentProduct = {
                id: pmDoc.id,
                ...pmDoc.data(),
              };
              pmData = [...pmData, currentProduct];
            }
          });
          setTableData(pmData);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    // });
  }, [currentUser , history]);

  return (
    <div>
      <Container className="text-center">
        <MaterialTable
          columns={[
            { title: "ห้องประชุม", field: "roomName" },
            { title: "หัวข้อการประชุม", field: "title" },
            // { title: "รายละเอียด", field: "detail"},
            { title: "ผู้จอง", field: "displayName" },
            // { title: "อีเมลล์ผู้จอง", field: "email" },
            {
              title: "เวลาเริ่มประชุม",
              render: (rowData) =>
                `${
                  moment
                    .unix(rowData.start)
                    .isSame(moment.unix(rowData.end), "day")
                    ? `${moment
                        .unix(rowData.start)
                        .format("DD/MM/yy [เวลา] HH:mm")}  - ${moment
                        .unix(rowData.end)
                        .format("HH:mm")} น.`
                    : `${moment
                        .unix(rowData.start)
                        .format("DD/MM/yy HH:mm")} น. - ${moment
                        .unix(rowData.end)
                        .format("DD/MM/yy HH:mm")} น.`
                }`,
            },
            { title: "เวลาประชุม (นาที)", field: "duration" },
          ]}
          data={tableData}
          title={tableName}
          icons={tableIcons}
          options={{
            // showTitle: false,
            // exportButton: true,
            headerStyle: {
              backgroundColor: "#D7252A",
              color: "#ffffff",
              // padding: "3px",
            },
            rowStyle: (rowData) =>
              rowData.approved
              ? { backgroundColor: "#e3ffe0", textAlign: "center" }
              : rowData.rejected?{ backgroundColor: "#ffe5e5", textAlign: "center" }:{ backgroundColor: "#fffec2", textAlign: "center" },
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100],
            // sorting: false,
            // grouping: true,
            actionsColumnIndex: -1,
            // tableLayout: "fixed",
          }}
          actions={[
            {
              icon: () => <Edit />,
              tooltip: "ดูข้อมูล",
              onClick: (event, rowData) => OnOpenModal(rowData),
              // props.history.push("/user-detail/" + rowData.id),
            },
          ]}
          localization={localeTh}
        />
      </Container>
    </div>
  );
};

export default AllPayment;
