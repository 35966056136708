import { Container, Grid } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import CardRoom from "./CardRoom";
import firebase from "firebase/app";
import { keys } from "@material-ui/core/styles/createBreakpoints";

const firestore = firebase.firestore();

export default function AllRoom() {
  const [data, setData] = useState();
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      const meetRoomRef = firestore.collection("meeting-room").where("isShow","==",true);
      meetRoomRef
        .get()
        .then(function (querySnapshot) {
          let pmData = [];
          querySnapshot.forEach(function (pmDoc) {
            // doc.data() is never undefined for query doc snapshots
            if (pmDoc.exists) {
              const currentProduct = {
                id: pmDoc.id,
                ...pmDoc.data(),
              };
              pmData = [...pmData, currentProduct];
            }
          });
          setData(pmData);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });
  }, []);
  return (
    <div>
      {console.log(data)}
      <Container>
        <Grid container direction="row">
          {data &&
            data.map((room, key) => (
              <Grid key={keys} item xs={12} sm={12} md={6} lg={4} xl={3}>
                <CardRoom room={room} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
}
