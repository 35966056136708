import React, { forwardRef, useEffect, useState } from "react";
import firebase from "firebase/app";
import {
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import MaterialTable from "material-table";

import Edit from "mdi-react/EyeIcon";
import OnOpenModal from "../AllowEvent/ModalInfo";

import { localeTh, tableIcons } from "../AllowEvent/configTable";
import { AuthContext } from "../../App/firebaseAuth";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

const firestore = firebase.firestore();

export default function Stat() {
  // const [data, setData] = useState();
  const currentUser = useContext(AuthContext);
  const history = useHistory();
  const [countt, setCountt] = useState();
  const [tableData, setTableData] = useState();
  const [reserver, setReserver] = useState();
  const [statusAp, setStatusAp] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [stopDate, setStopDate] = useState(new Date());
  useEffect(() => {
    if (currentUser.currentUser.role <= 1) {
      history.push("/dashboard");
    }
    const getData = async () => {
      const userRef = firestore
        .collection("booking-room")
        .where("start", ">=", moment(startDate).unix())
        .where("start", "<=", moment(stopDate).unix());
      // .where("start", ">=", 1641000702)
      // .where("start", "<=", 1646091102);
      userRef.get().then(function (querySnapshot) {
        let pmData = [];
        let counter = {};
        let reserver = {};
        let statusAp = {};
        querySnapshot.forEach(function (pmDoc) {
          // doc.data() is never undefined for query doc snapshots
          if (pmDoc.exists) {
            const currentProduct = {
              id: pmDoc.id,
              ...pmDoc.data(),
            };
            console.log(pmDoc.data());
            var key = currentProduct.roomName;
            var key2 = currentProduct.displayName;
            var key3 = currentProduct.approved;
            counter[key] = (counter[key] || 0) + 1;
            reserver[key2] = (reserver[key2] || 0) + 1;
            statusAp[key3 ? "อนุมัติ" : "ไม่อนุมัติ"] =
              (statusAp[key3 ? "อนุมัติ" : "ไม่อนุมัติ"] || 0) + 1;
            pmData = [...pmData, currentProduct];
          }
        });
        setTableData(pmData);
        setCountt(counter);
        setReserver(reserver);
        setStatusAp(statusAp);
      });
    };
    getData();
  }, [stopDate, startDate , currentUser.currentUser.role, history]);

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, label, val }, ref) => {
      // console.log(val);
      return (
        <TextField
          color="secondary"
          variant="outlined"
          label={label}
          value={val ? moment(val).format("DD/MM/YY") : ""}
          ref={ref}
          fullWidth
          onClick={onClick}
          onFocus={onClick}
        />
      );
    }
  );

  const optionChart = {
    series: countt ? Object.values(countt) : null,
    options: {
      chart: {
        height: 800,
        type: "pie",
      },
      labels: countt ? Object.keys(countt) : null,
      responsive: [
        {
          breakpoint: 800,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const optionChart2 = {
    series: statusAp ? Object.values(statusAp) : null,
    options: {
      chart: {
        height: 800,
        type: "pie",
      },
      labels: statusAp ? Object.keys(statusAp) : null,
      responsive: [
        {
          breakpoint: 800,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const optionBarChart = {
    series: [
      {
        data: countt ? Object.values(countt) : null,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: countt ? Object.keys(countt) : null,
      },
    },
  };

  const optionBarChart2 = {
    series: [
      {
        data: reserver ? Object.values(reserver) : null,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: reserver ? Object.keys(reserver) : null,
      },
    },
  };

  return (
    <Container>
      <Paper elevation={3}>
        <Typography variant="h5" className="text-center p-4">
          สถิติการจองห้องประชุม
        </Typography>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          style={{ padding: 20 }}
        >
          <Grid item xs={12} sm={12} lg={6} xl={4}>
            <DatePicker
              customInput={
                <ExampleCustomInput label={"เวลาเริ่มต้น"} val={startDate} />
              }
              selected={startDate}
              locale="th"
              onChange={(date) => {
                setStartDate(date);
              }}
              // withPortal
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={4}>
            <DatePicker
              customInput={
                <ExampleCustomInput label={"เวลาสิ้นสุด"} val={stopDate} />
              }
              selected={stopDate}
              locale="th"
              onChange={(date) => {
                setStopDate(date);
              }}
              // withPortal
            />
          </Grid>
        </Grid>
        <hr />
        {countt && Object.keys(countt).length > 0 ? (
          <>
            <Typography variant="h6" className="text-center p-2">
              การอนุมัติคำขอ
            </Typography>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              style={{ padding: 20 }}
            >
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                {statusAp && (
                  <ReactApexChart
                    options={optionChart2.options}
                    series={optionChart2.series}
                    type="pie"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={6}>
                {countt && statusAp && (
                  <div>
                    <b>การอนุมัติคำขอ</b> <br />
                    อนุมัติ: {statusAp["อนุมัติ"]} <br />
                    ไม่อนุมัติ: {statusAp["ไม่อนุมัติ"]} <br />
                    <br />
                    <b>ชื่อห้องประชุม</b> <br />
                    {Object.keys(countt).map((ct, key) => (
                      <div key={key}>
                        {ct}:{countt[ct]}{" "}
                      </div>
                    ))}
                  </div>
                )}
              </Grid>
            </Grid>
            <hr />
            <Typography variant="h6" className="text-center p-2">
              ข้อมูลชื่อห้องประชุม
            </Typography>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              style={{ padding: 20 }}
            >
              <Grid item xs={12} sm={12} lg={6} xl={4}>
                {countt && (
                  <ReactApexChart
                    options={optionChart.options}
                    series={optionChart.series}
                    type="pie"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} lg={6} xl={4}>
                {countt && (
                  <ReactApexChart
                    options={optionBarChart.options}
                    series={optionBarChart.series}
                    type="bar"
                    height={350}
                  />
                )}
              </Grid>
            </Grid>
            <hr />
            <Typography variant="h6" className="text-center p-2">
              ข้อมูลผู้จอง
            </Typography>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              style={{ padding: 20 }}
            >
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                {reserver && (
                  <ReactApexChart
                    options={optionBarChart2.options}
                    series={optionBarChart2.series}
                    type="bar"
                    height={500}
                  />
                )}
              </Grid>
            </Grid>
            <MaterialTable
              columns={[
                { title: "ห้องประชุม", field: "roomName" },
                { title: "หัวข้อการประชุม", field: "title" },
                // { title: "รายละเอียด", field: "detail"},
                { title: "ผู้จอง", field: "displayName" },
                // { title: "อีเมลล์ผู้จอง", field: "email" },
                {
                  title: "เวลาเริ่มประชุม",
                  render: (rowData) =>
                    `${
                      moment
                        .unix(rowData.start)
                        .isSame(moment.unix(rowData.end), "day")
                        ? `${moment
                            .unix(rowData.start)
                            .format("DD/MM/yy [เวลา] HH:mm")}  - ${moment
                            .unix(rowData.end)
                            .format("HH:mm")} น.`
                        : `${moment
                            .unix(rowData.start)
                            .format("DD/MM/yy HH:mm")} น. - ${moment
                            .unix(rowData.end)
                            .format("DD/MM/yy HH:mm")} น.`
                    }`,
                },
                { title: "เวลาประชุม (นาที)", field: "duration" },
              ]}
              data={tableData}
              title={"ข้อมูลการจองย้อนหลัง"}
              icons={tableIcons}
              options={{
                // showTitle: false,
                // exportButton: true,
                headerStyle: {
                  backgroundColor: "#D7252A",
                  color: "#ffffff",
                  // padding: "3px",
                },
                rowStyle: (rowData) =>
                  rowData.approved
                    ? { backgroundColor: "#e3ffe0", textAlign: "center" }
                    : rowData.rejected
                    ? { backgroundColor: "#ffe5e5", textAlign: "center" }
                    : { backgroundColor: "#fffec2", textAlign: "center" },
                pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                // sorting: false,
                // grouping: true,
                actionsColumnIndex: -1,
                // tableLayout: "fixed",
              }}
              actions={[
                {
                  icon: () => <Edit />,
                  tooltip: "ดูข้อมูล",
                  onClick: (event, rowData) => OnOpenModal(rowData),
                  // props.history.push("/user-detail/" + rowData.id),
                },
              ]}
              localization={localeTh}
            />
          </>
        ) : null}
      </Paper>
    </Container>
  );
}
