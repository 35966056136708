import moment from "moment";
import firebase from "firebase/app";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../../constants";

const noData = (params) => {
  return params ? params : "-";
};

const meetingType = (params) => {
  console.log(params);
  switch (params) {
    case "hi":
      return "ประชุมผู้บริหาร";
    case "md":
      return "ประชุมภายใน";
    case "lo":
      return "ประชุมภายนอก";

    default:
      return "ไม่ทราบ";
  }
};

const OnOpenModal = (rowData) => {

  return Swal.fire({
    title: "กรุณาตรวจสอบข้อมูล",
    confirmButtonColor: "#D7252A",
    icon: "question",
    confirmButtonText: "ยืนยัน ข้อมูลถูกต้อง",
    cancelButtonText: "แก้ไขข้อมูล",
    showCancelButton: true,
    html: `
  <div class="list-group text-left">
  <div class="list-group-item" >
        <p class="text-secondary">ชื่อห้องประชุม</p>
        <small>${rowData.roomName}</small>
    </div>
    <div class="list-group-item" >
        <p class="text-secondary">ประเภทการประชุม</p>
        <small>${meetingType(rowData.priority)}</small>
    </div>
    <div class="list-group-item" >
        <p class="text-secondary">ชื่อการประชุม</p>
        <small>${rowData.title}</small>
    </div>
    <div class="list-group-item" >
        <p class="text-secondary">รายละเอียด - วาระการประชุม</p>
        <small>${noData(rowData.detail)}</small>
    </div>
    <div class="list-group-item" >
        <p class="text-secondary">เวลาประชุม</p>
        <small>
        ${
          moment.unix(rowData.start).isSame(moment.unix(rowData.end), "day")
            ? `${moment
                .unix(rowData.start)
                .format("DD/MM/yy [เวลา] HH:mm")}  - ${moment
                .unix(rowData.end)
                .format("HH:mm")} น. ( ${rowData.duration} นาที)`
            : `${moment
                .unix(rowData.start)
                .format("DD/MM/yy HH:mm")} น. - ${moment
                .unix(rowData.end)
                .format("DD/MM/yy HH:mm")} น. ( ${rowData.duration} นาที)`
        }
        </small>

    </div>
    <div class="list-group-item" >
        <p class="text-secondary">คำขอเพิ่มเติม</p>
        <small>${noData(rowData.request)}</small>
    </div>
</div>
  `,
  }).then(async (result) => {
    if (result.isConfirmed) {
      const idToken = await firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true);

      const result = await axios.post(
        config.API_URL + "/user/add-meeting-event",
        {
          idToken,
          data: rowData,
        }
      );
      if (result.data.status) {
        Swal.fire({
          title: "สำเร็จ",
          text: "ทำการจองเรียบร้อย โปรดรอให้เจ้าหน้าที่ทำการอนุมัติ",
          icon: "success",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#D7252A",
        });
        return false
      } else {
        Swal.fire({
          title: "เกิดข้อผิดพลาด",
          text: "กรุณาลองใหม่อีกครั้งในภายหลัง",
          icon: "error",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#D7252A",
        });
        return true
      }
    } else if (result.isDismissed	) {
      return true
    }
  });
  // console.log(result2)
  // return result2
};

export default OnOpenModal;
