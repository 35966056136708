// import React from 'react';
// import { PDFViewer } from '@react-pdf/renderer';
// import MyDocument from './MyDocument'

// const App = () => (
//   <PDFViewer>
//     <MyDocument />
//   </PDFViewer>
// );

// export default App;

// import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import pdfFile from './a.pdf'

// function MyApp() {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//   const [file, setFile] = useState(pdfFile);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   return (
//     <div>
//       <Document
//         file={file}
//         onLoadSuccess={onDocumentLoadSuccess}
//       >
//         <Page pageNumber={pageNumber} />
//       </Document>
//       <p>Page {pageNumber} of {numPages}</p>
//     </div>
//   );
// }

// export default MyApp;

import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import samplePDF from "./a.pdf";
import ReactDOM from "react-dom";
import Draggable from "react-draggable"; // The default

// import './Sample.less';

export default function Sample() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const ref = React.useRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const Drag = () => {
    return (
      <Draggable
      bounds={{left:0,top:0,right:300,bottom:600}}
      >
        <div
          style={{
            width: 100,
            height: 100,
            backgroundColor: "black",
            position: "relative",
            top: 10,
          }}
        ></div>
      </Draggable>
    );
  };
  

  return (
    <>
      <Document
        file={samplePDF}
        onLoadSuccess={onDocumentLoadSuccess}
        onItemClick={(e) => console.log(e)}
        renderMode="canvas"
        // onClick={(e)=>console.log(e)}
      >
        <Page pageNumber={pageNumber} inputRef={ref} />
      </Document>
      {ref && console.log(ref)}
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
        <button
          type="button"
          // disabled={pageNumber >= numPages}
          onClick={() => {
            console.log(ref.current);
            // ReactDOM.render(
            //   <div>
            //     <div
            //       style={{
            //         width: 100,
            //         height: 100,
            //         backgroundColor: "black",
            //         position: "relative",
            //         top: 10,
            //       }}
            //     ></div>
            //   </div>,
            //   ref.current
            // );
            const element = document.createElement("div");
            // const element2 = React.createElement(Drag,{} )
            console.log(element)
            // console.log(element2)
            // element.textContent = "Hello World";
            const aa = ref.current.appendChild(element);
            ReactDOM.render(
              <Drag />,
              aa,
            );
          }}
        >
          test
        </button>
      </div>
    </>
  );
}
