import firebase from "firebase/app";
import Swal from "sweetalert2";

const firestore = firebase.firestore();

/* eslint-disable no-alert */

export default (async function showResults(values) {
  console.log("2" , values)
  const { id ,tableData ,  ...newVal} = values
  
  firestore
    .collection("meeting-room")
    .doc(id)
    .update({
      ...newVal
    })
    .then(() => {
      Swal.fire({
        title: "สำเร็จ",
        text: "ทำการแก้ไขเรียบร้อย",
        icon: "success",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#D7252A",
      });
    })
    .catch((e) => {
      console.log(e)
      Swal.fire({
        title: "เกิดข้อผิดพลาด",
        text: "กรุณาลองใหม่อีกครั้งในภายหลัง",
        icon: "error",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#D7252A",
      });
    });

});
