import moment from "moment";
import Swal from "sweetalert2";

const noData = (params) => {
  return params ? params : "-";
};

const meetingType = (params) => {
  console.log(params)
  switch (params) {
    case "hi":
      return "ประชุมผู้บริหาร";
    case "md":
      return "ประชุมภายใน";
    case "lo":
      return "ประชุมภายนอก";

    default:
      return "ไม่ทราบ";
  }
};

const OnOpenModal = (rowData) => {
  Swal.fire({
    title: "รายละเอียดการจอง",
    confirmButtonColor: "#D7252A",
    html: `
  <div class="list-group text-left">
  <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">ชื่อห้องประชุม</p>
        <small>${rowData.roomName}</small>
    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">ประเภทการประชุม</p>
        <small>${
          meetingType(rowData.priority)
        }</small>
    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">ชื่อการประชุม</p>
        <small>${rowData.title}</small>
    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">รายละเอียดการประชุม</p>
        <small>${noData(rowData.detail)}</small>
    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">ผู้จอง</p>
        <small>${rowData.displayName} (${rowData.email})</small>
    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">เวลาประชุม</p>
        <small>
        ${
          moment.unix(rowData.start).isSame(moment.unix(rowData.end), "day")
            ? `${moment
                .unix(rowData.start)
                .format("DD/MM/yy [เวลา] HH:mm")}  - ${moment
                .unix(rowData.end)
                .format("HH:mm")} น. ( ${rowData.duration} นาที)`
            : `${moment
                .unix(rowData.start)
                .format("DD/MM/yy HH:mm")} น. - ${moment
                .unix(rowData.end)
                .format("DD/MM/yy HH:mm")} น. ( ${rowData.duration} นาที)`
        }
        </small>

    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">คำขอเพิ่มเติม</p>
        <small>${noData(rowData.request)}</small>
    </div>
    <div class="list-group-item" >
        <p class="${
          rowData.approved
            ? "text-success"
            : rowData.rejected
            ? "text-danger"
            : "text-warning"
        }">สถานะ</p>
        <small>${
          rowData.approved
            ? "อนุมัติแล้ว"
            : rowData.rejected
            ? "ไม่อนุมัติ"
            : "กำลังตรวจสอบ"
        }</small>
    </div>

    
  
</div>
  `,
  });
};

export default OnOpenModal;
